const INITIAL_STATE = {
    isProcessing: false,
    success: null,
    message: '',
    isAuth: false
}

const AuthReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'AUTH':
            return Object.assign({}, state, {
                isProcessing: true,
                message: '',
                isAuth: false
            });
        case 'AUTH_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                success: true,
                message: '',
                isAuth: true
            });
        case 'AUTH_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                success: false,
                message: payload.message,
                isAuth: false
            });
        default:
            return state;
    }
}

export default AuthReducer;
