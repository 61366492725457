import axios from 'axios';
import { setAuthorization } from 'util/localstorage'

export const authenticateAction = () => async (dispatch) => {
    let req = {
        checkout: 'checkout',
        checkout_key: Number(new Date())
    };

    try {
        dispatch({ type: 'LOGOUT' })
        dispatch({ type: 'AUTH' })
        const res = await axios.post('/checkout/auth', req);
        const token = res.data.token;
        const prefix = res.data.prefix;

        dispatch({ type: 'AUTH_SUCCESS' });

        setAuthorization(prefix, token);

        return res.data;
    } catch ({ response, ...rest }) {
        dispatch({
            type: 'AUTH_FAIL',
            payload: response
        });
    }
}

export const logoutAction = () => (dispatch) => {
    dispatch({
        type: 'LOGOUT',
    })
}