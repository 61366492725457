import axios from 'axios';

export const setPaymentAction = (value) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT',
        payload: value
    });
}

export const setPaymentMessageAction = (value) => async (dispatch) => {
    dispatch({
        type: 'PAYMENT_MESSAGE',
        payload: value
    });
}


export const installmentsAction = (amount) => async (dispatch) => {
    const req = {
        amount: amount
    };
    try {
        const res = await axios.post('/checkout/installments', req)
        dispatch({ type: 'PAYMENT_INSTALLMENTS_SUCCESS', payload: res.data });
        return res.data;
    } catch ({ response, ...rest }) {
        dispatch({
            type: 'PAYMENT_INSTALLMENTS_FAIL',
            payload: response.data
        });
    }
}