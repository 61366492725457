const INITIAL_STATE = {
    isProcessing: false,
    message: '',
    isCheckout: false,
    isPaid: false
}

const CheckoutReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'CHECKOUT':
            return Object.assign({}, state, {
                isProcessing: true,
                message: '',
                isPaid: false,
                isCheckout: true
            });
        case 'CHECKOUT_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                message: '',
                isPaid: payload.checkout.paid
            });
        case 'CHECKOUT_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                message: payload.message,
                isPaid: false
            });
        default:
            return state;
    }
}

export default CheckoutReducer;
