import axios from 'axios';

export const findAllAction = () => async (dispatch) => {
    try {
        dispatch({ type: 'LIST_PRODUCT' })
        const res = await axios.get('/product');

        dispatch({
            type: 'LIST_PRODUCT_SUCCESS',
            payload: res.data
        });

        return res.data;
    } catch ({ response, ...rest }) {
        dispatch({
            type: 'LIST_PRODUCT_FAIL',
            payload: response
        });
    }
}