import axios from 'axios';

export const checkoutAction = (installments, amount, discount, customer, billing, coupon, items) => async (dispatch) => {
    const req = {
        amount: amount,
        installments: installments,
        discount: discount,
        customer: {
            ...customer,
            phone: '+' + customer.phone.code + customer.phone.number
        },
        billing: billing,
        coupon: coupon,
        items: items.map(x => ({ product: x.id, name_product: x.name, quantity: x.quantity, price: x.price }))
    }
    try {
        dispatch({ type: 'CHECKOUT' });
        const res = await axios.post('/checkout', req)
        dispatch({ type: 'CHECKOUT_SUCCESS', payload: res.data });
        return res.data;
    } catch ({ response, ...rest }) {
        dispatch({
            type: 'CHECKOUT_FAIL',
            payload: response.data
        });
    }
}