const INITIAL_STATE = {
    message: '',
    isPayment: false,
    installments: []
}

const PaymentReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'PAYMENT':
            return Object.assign({}, state, {
                message: '',
                isPayment: payload
            });
        case 'PAYMENT_MESSAGE':
            return Object.assign({}, state, {
                message: payload,
                isPayment: false
            });
        case 'PAYMENT_INSTALLMENTS_SUCCESS':
            return Object.assign({}, state, {
                message: '',
                installments: payload.installments
            });
        case 'PAYMENT_INSTALLMENTS_FAIL':
            return Object.assign({}, state, {
                message: payload.message,
            });
        default:
            return state;
    }
}

export default PaymentReducer;
