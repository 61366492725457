import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select, Button, Row, Col, Steps, Modal, Result } from 'antd';
import { SolutionOutlined, DollarCircleOutlined, CheckCircleOutlined, ArrowRightOutlined, SmileOutlined } from '@ant-design/icons';
import { InputMask } from 'components';
import NumberFormat from 'react-number-format';
import { setPaymentAction, installmentsAction } from 'actions/payment_action';
import { checkoutAction } from 'actions/checkout_action';
import CheckoutCart from './CheckoutCart';

const CheckoutFormCustomer = (props) => {
    const { form, onFinish } = props;
    const initialValues = {
        phone: { code: '55' },
        documents: { type: 'cpf' }
    }
    return (
        <Form form={form} layout="vertical" name="control-hooks" onFinish={onFinish} initialValues={initialValues}>
            <Row gutter={24}>
                <Col xl={12} xs={24}>
                    <Form.Item name="name" label="Nome Completo" rules={[{ required: true, message: 'Informe um nome' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col xl={12} xs={24}>
                    <Form.Item name="email" label="E-mail" rules={[{ required: true, message: 'Informe um e-mail válido', type: 'email' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                    <Form.Item label="Contato" required>
                        <Input.Group compact>
                            <Form.Item name={['phone', 'code']} noStyle rules={[{ required: true, message: 'Informe o código de contato' }]}>
                                <Select size="large" style={{ width: '40%' }}>
                                    <Select.Option value="55">+55</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name={['phone', 'number']} noStyle rules={[{ required: true, message: 'Informe o número de contato' }]}>
                                <Input size="large" style={{ width: '60%' }} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                    <Form.Item label="Documento" required>
                        <Input.Group compact>
                            <Form.Item name={['documents', 'type']} noStyle rules={[{ required: true, message: 'Informe um tipo de documento' }]}>
                                <Select size="large" style={{ width: '40%' }}>
                                    <Select.Option value="cpf">CPF</Select.Option>
                                    {/* <Select.Option value="passport">Passaporte</Select.Option>
                                    <Select.Option value="others">Outros</Select.Option> */}
                                </Select>
                            </Form.Item>
                            <Form.Item name={['documents', 'number']} noStyle rules={[{ required: true, message: 'Informe um documento' }]}>
                                <InputMask size="large" mask="999.999.999-99" placeholder="000.000.000-00" style={{ width: '60%' }} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} style={{ textAlign: "right" }}>
                    <Form.Item>
                        <Button size="large" style={{ minWidth: 200 }} type="primary" htmlType="submit" icon={<ArrowRightOutlined />}>Avançar</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

const CheckoutFormBilling = (props) => {
    const { form, onFinish } = props
    const initialValues = {
        address: { country: 'br' }
    }
    return (
        <Form form={form} layout="vertical" name="control-hooks" onFinish={onFinish} initialValues={initialValues}>
            <Row gutter={24}>
                <Col md={12} xs={24}>
                    <Form.Item name={['card', 'number']} label="Número do Cartão" rules={[{ required: true, message: 'Informe o número do cartão' }]}>
                        <InputMask size="large" mask="9999 9999 9999 9999" />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item name={['card', 'expiration']} label="Expiração" rules={[{ required: true, message: 'Informe a expiração' }]}>
                        <InputMask size="large" mask="99/99" placeholder="MM/YY" />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item name={['card', 'cvv']} label="CVV" rules={[{ required: true, message: 'Informe o CVV do cartão' }]}>
                        <InputMask size="large" mask="999" />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item name={['card', 'holder_name']} label="Nome do Cartão" rules={[{ required: true, message: 'Informe o nome escrito no cartão' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item name={['address', 'country']} label="País" rules={[{ required: true, message: 'Informe um país' }]}>
                        <Select size="large">
                            <Select.Option value="br">Brasil</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item name={['address', 'state']} label="Estado" rules={[{ required: true, message: 'Informe um estado' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item name={['address', 'city']} label="Cidade" rules={[{ required: true, message: 'Informe uma cidade' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item name={['address', 'street']} label="Endereço" rules={[{ required: true, message: 'Informe um endereço' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item name={['address', 'street_number']} label="Número" rules={[{ required: true, message: 'Informe o número' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item name={['address', 'zipcode']} label="Código Postal" rules={[{ required: true, message: 'Informe o código postal' }]}>
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col xs={24} style={{ textAlign: "right" }}>
                    <Form.Item>
                        <Button size="large" style={{ minWidth: 200 }} type="primary" htmlType="submit" icon={<ArrowRightOutlined />}>Avançar</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

const CheckoutConclusion = (props) => {
    const [installment, setInstallment] = React.useState(1);

    const onPayment = () => {
        props.onPayment(installment);
    }

    return (
        <Result
            icon={<SmileOutlined />}
            title="Escolha a quantidade de parcelas e prossiga com a compra"
            extra={[
                <Row justify="center">
                    <Col md={12} xs={24}>
                        <Select defaultValue={installment}
                            size="large"
                            style={{ width: '100%', marginBottom: 30 }}
                            onChange={(value) => setInstallment(value)}
                        >
                            {props.installments.map(x =>
                                (<Select.Option value={x.installment}>
                                    <NumberFormat displayType="text"
                                        decimalScale={2}
                                        decimalSeparator=","
                                        prefix={x.installment + 'x R$ '}
                                        thousandSeparator="."
                                        fixedDecimalScale={true}
                                        value={x.amount}
                                    />
                                </Select.Option>)
                            )}
                        </Select>
                    </Col>
                    <Col xs={24}>
                        <CheckoutCart />
                    </Col>
                    <Col xs={24}>
                        <Button size="large" style={{ minWidth: 200 }} type="primary" icon={<DollarCircleOutlined />} onClick={onPayment}>Pagar</Button>
                    </Col>
                </Row>
            ]}
        />
    )
}

const CheckoutForm = (props) => {
    const [stepCurrent, setStepCurrent] = React.useState(0);
    const [form, setForm] = React.useState({});
    const [formCustomer] = Form.useForm();
    const [formBilling] = Form.useForm();
    const { cart, payment, coupon } = props;
    const { setPaymentAction, checkoutAction, installmentsAction } = props;

    React.useEffect(() => {
        const { total } = cart;
        installmentsAction(total.amount)
    }, [cart.total]);

    const onFinishCustomer = (values) => {
        setForm({ customer: values });
        setStepCurrent(stepCurrent + 1)
    }

    const onFinishBilling = (values) => {
        setForm({ ...form, billing: values });
        setStepCurrent(stepCurrent + 1)
    }

    const onPayment = (installments) => {
        checkoutAction(
            installments,
            cart.total.amount,
            cart.total.discount,
            form.customer,
            form.billing,
            Object.keys(coupon.coupon).length > 0 ? coupon.coupon.id : null,
            cart.products);
    }

    return (
        <Modal
            width="80%"
            visible={true}
            title="Pagamento"
            footer={null}
            destroyOnClose={true}
            onCancel={() => setPaymentAction(false)}
        >
            <Row justify="center">
                <Col xl={16} xs={24}>
                    <Steps current={stepCurrent} style={{ marginBottom: 20 }} direction="horizontal">
                        <Steps.Step key="customer" title="Seus Dados" icon={<SolutionOutlined />} />
                        <Steps.Step key="billing" title="Dados do Cartão" icon={<DollarCircleOutlined />} />
                        <Steps.Step key="payment" title="Conclusão" icon={<CheckCircleOutlined />} />
                    </Steps>
                    {stepCurrent === 0 &&
                        <CheckoutFormCustomer form={formCustomer} onFinish={onFinishCustomer} />
                    }
                    {stepCurrent === 1 &&
                        <CheckoutFormBilling form={formBilling} onFinish={onFinishBilling} />
                    }
                    {stepCurrent === 2 &&
                        <CheckoutConclusion installments={payment.installments} onPayment={onPayment} />
                    }
                </Col>
            </Row>
        </Modal>
    )
}

const mapStateToProps = state => ({
    cart: state.cart,
    payment: state.payment,
    coupon: state.coupon
})
export default connect(mapStateToProps, { setPaymentAction, checkoutAction, installmentsAction })(CheckoutForm);