import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import CheckoutForm from './CheckoutForm';
import CheckoutProduct from './CheckoutProduct';
import CheckoutCart from './CheckoutCart';
import CheckoutFinish from './CheckoutFinish';

const Checkout = (props) => {
    return (
        <Row className="height-calc" align="middle" justify="center">
            {!props.checkout.isCheckout ? (
                <>
                    <Col className="height-calc" lg={12} md={24}>
                        <CheckoutProduct />
                        {props.payment.isPayment && <CheckoutForm />}
                    </Col>
                    <Col className="height-calc" lg={12} md={24} style={{ backgroundColor: 'white' }}>
                        <CheckoutCart />
                    </Col>
                </>
            ) : (
                    <Col className="height-calc" lg={12} md={24}>
                       <CheckoutFinish />
                    </Col>
                )
            }
        </Row >
    );
}

const mapStateToProps = state => ({
    payment: state.payment,
    checkout: state.checkout
})
export default connect(mapStateToProps, {})(Checkout);