const AUTHORIZATION = 'authorization';

export const setAuthorization = (prefix, token) => (
    localStorage.setItem(AUTHORIZATION, `${prefix} ${token}`)
);

export const getAuthorization = () => (
    localStorage.getItem(AUTHORIZATION)
);

export const removeAuthorization = () => (
    localStorage.removeItem(AUTHORIZATION)
);
