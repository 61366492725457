import React from 'react';
import { connect } from 'react-redux';
import { Result, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { logoutAction } from 'actions/auth_action';

const CheckoutFinish = (props) => {
    const { checkout, logoutAction } = props;

    const onBuyAgain = () => {
        window.location.reload();
    }

    if (checkout.isProcessing) {
        return (
            <Result
                icon={<LoadingOutlined />}
                title="Processando pagamento..."
                subTitle="Esse processo pode demorar um tempo. Por favor, aguarde!"
            />
        )
    } else if(checkout.isPaid){
        return (
            <Result
                status="success"
                title="Compra Realizada com Sucesso"
                subTitle="Dentro de alguns minutos você receberá um e-mail com a chave de acesso."
                extra={[
                    <Button key="buy" onClick={onBuyAgain}>Comprar Novamente?</Button>,
                ]}
            />
        )
    }else{
        return (
            <Result
                status="error"
                title="Sua compra não pôde ser realizada"
                subTitle="Verifique se os dados do cartão estão corretos e tente novamente. Se o problema persistir, entre em contato com nosso suporte."
                extra={[
                    <Button key="buy" onClick={onBuyAgain}>Comprar Novamente?</Button>,
                ]}
            />
        )   
    }
}

const mapStateToProps = state => ({
    checkout: state.checkout
})
export default connect(mapStateToProps, { logoutAction })(CheckoutFinish);