const INITIAL_STATE = {
    isProcessing: false,
    success: null,
    message: '',
    products: []
}

const ProductReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'LIST_PRODUCT':
            return Object.assign({}, state, {
                isProcessing: true,
                message: '',
                products: []
            });
        case 'LIST_PRODUCT_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                success: true,
                message: '',
                products: payload.products
            });
        case 'LIST_PRODUCT_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                success: false,
                message: payload.message,
                products: []
            });
        default:
            return state;
    }
}

export default ProductReducer;
