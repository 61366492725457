const environments = {
        dev: {
                serverURL: "http://localhost:8080/api",
        },
        staging: {
                serverURL: "",
        },
        production: {
                serverURL: "https://app.onbott.com/api",
        }
}

module.exports = environments.production;
