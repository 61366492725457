import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, List, Avatar } from 'antd';
import NumberFormat from 'react-number-format';
import { RobotFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { findAllAction } from 'actions/product_action';
import { addCartAction } from 'actions/cart_action';

const CheckoutProduct = (props) => {
    const { findAllAction, addCartAction, product, payment } = props;

    React.useEffect(() => {
        findAllAction();
    }, [findAllAction]);

    const onAddCart = (item) => {
        addCartAction(item)
    }

    return (
        <List
            itemLayout="horizontal"
            dataSource={product.products}
            renderItem={item => (
                <List.Item style={{ backgroundColor: '#fff', padding: 30 }}>
                    <List.Item.Meta
                        avatar={<Avatar style={{ backgroundColor: '#1890ff' }} icon={<RobotFilled />} />}
                        title={item.name}
                        description={
                            <Row>
                                <Col xl={16} lg={16} md={16} xs={24}>{item.description}</Col>
                                <Col xl={8} lg={8} md={8} xs={24} style={{ textAlign: "right" }}>
                                    <NumberFormat
                                        displayType="text"
                                        decimalScale={2}
                                        decimalSeparator=","
                                        prefix="R$ "
                                        thousandSeparator="."
                                        fixedDecimalScale={true}
                                        value={item.price}
                                        style={{ fontSize: 14, fontWeight: "bold" }}
                                    />
                                </Col>
                                {!payment.isPayment &&
                                    <Col xl={24} lg={24} md={24} xs={24} style={{ textAlign: "right", marginTop: 10 }}>
                                        <Button icon={<ShoppingCartOutlined />} onClick={() => onAddCart(item)}>Adicionar ao carrinho</Button>
                                    </Col>
                                }
                            </Row>
                        }
                    />
                </List.Item>
            )}
        />
    );
}

const mapStateToProps = state => ({
    product: state.product,
    cart: state.cart,
    payment: state.payment
})
export default connect(mapStateToProps, { findAllAction, addCartAction })(CheckoutProduct);