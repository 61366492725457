import axios from 'axios';

export const searchByCodeAction = (code, products) => async (dispatch) => {
    try {
        const req = {
            code: code,
            products: products
        }
        dispatch({ type: 'SEARCHBYCODE_COUPON' })
        const res = await axios.post('/coupon/search', req);

        dispatch({
            type: 'SEARCHBYCODE_COUPON_SUCCESS',
            payload: res.data
        });

        return res.data;
    } catch ({ response, ...rest }) {
        dispatch({
            type: 'SEARCHBYCODE_COUPON_FAIL',
            payload: response.data
        });
    }
}