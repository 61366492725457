const INITIAL_STATE = {
    isProcessing: false,
    success: null,
    message: '',
    coupon: {}
}

const CouponReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'SEARCHBYCODE_COUPON':
            return Object.assign({}, state, {
                isProcessing: true,
                message: '',
                coupon: {}
            });
        case 'SEARCHBYCODE_COUPON_SUCCESS':
            return Object.assign({}, state, {
                isProcessing: false,
                success: true,
                message: '',
                coupon: payload.coupon
            });
        case 'SEARCHBYCODE_COUPON_FAIL':
            return Object.assign({}, state, {
                isProcessing: false,
                success: false,
                message: payload.message,
                coupon: {}
            });
        default:
            return state;
    }
}

export default CouponReducer;
