import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Input, Avatar, Typography, Empty, Col, Row, Alert } from 'antd';
import { PlusOutlined, MinusOutlined, CreditCardOutlined, CloseCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { plusCartAction, minusCartAction, removeCartAction, setTotalCartAction } from 'actions/cart_action';
import { searchByCodeAction } from 'actions/coupon_action';
import { setPaymentAction, setPaymentMessageAction } from 'actions/payment_action';

const CheckoutCart = (props) => {
    const { cart, coupon, payment } = props;
    const {
        plusCartAction,
        minusCartAction,
        searchByCodeAction,
        setPaymentAction,
        removeCartAction,
        setPaymentMessageAction,
        setTotalCartAction } = props;

    React.useEffect(() => {
        if (cart.products.length > 0 && coupon.coupon) {
            onSearchByCode(coupon.coupon.code);
        }
    }, [cart])

    const getDiscount = () => {
        let discount = 0;
        if (coupon.coupon.value) {
            discount = coupon.coupon.value;
        }
        return discount;
    }

    const getSummary = () => {
        return cart.products.reduce((x, y) => x + (y.quantity * y.price), (-1 * getDiscount()));
    }

    const onSearchByCode = (code) => {
        if (code) searchByCodeAction(code, cart.products.map(x => x.id));
    }

    const onPayment = () => {
        if (getSummary() > 0) {
            setTotalCartAction(getSummary(), getDiscount());
            setPaymentAction(true)
        } else {
            setPaymentMessageAction('Total deve ser maior que R$ 0,00.');
        }
    }

    const onRemoveCart = (id) => {
        removeCartAction(id);
    }

    return (
        <>
            <Row>
                <Col xl={24} lg={24} md={24}>
                    <Table
                        locale={{ emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Carrinho Vazio" />) }}
                        pagination={false}
                        dataSource={cart.products}
                        rowKey={(item) => item.id}>
                        <Table.Column title="Produto" dataIndex="name" key="name" />
                        <Table.Column width={120} align="right" title="Vlr. Un." dataIndex="price" key="price" render={(x) =>
                            <NumberFormat displayType="text"
                                decimalScale={2}
                                decimalSeparator=","
                                prefix="R$ "
                                thousandSeparator="."
                                fixedDecimalScale={true}
                                value={x}
                            />
                        } />
                        <Table.Column width={140} align="center" title="Qtde." dataIndex="quantity" />
                        <Table.Column width={120} align="right" title="Total" render={(x) =>
                            <NumberFormat displayType="text"
                                decimalScale={2}
                                decimalSeparator=","
                                prefix="R$ "
                                thousandSeparator="."
                                fixedDecimalScale={true}
                                value={x.price * x.quantity}
                            />
                        } />
                        {!payment.isPayment &&
                            <Table.Column width={80} align="center" title="Remover" render={(x) =>
                                <Button type="primary" icon={<CloseCircleOutlined />} onClick={() => onRemoveCart(x.id)} />
                            } />
                        }
                    </Table>
                </Col>
            </Row>
            {!payment.isPayment &&
                <Row style={{ marginTop: 50, paddingLeft: 30, paddingRight: 30 }}>
                    <Col xl={10} lg={15} xs={24}>
                        <Input.Search
                            maxLength={50}
                            size="large"
                            placeholder="Cupom de Desconto"
                            onSearch={onSearchByCode}
                            enterButton
                        />
                        {coupon.message &&
                            <Alert message={coupon.message} type="error" showIcon closable={true} />
                        }
                    </Col>
                </Row>
            }
            <Row style={{ marginTop: 50, paddingLeft: 30, paddingRight: 30 }}>
                <Col xs={10} style={{ textAlign: "left" }}>
                    <Typography.Title style={{ color: 'red', marginBottom: 0 }} level={3}>Desconto:</Typography.Title>
                    {coupon.coupon.product &&
                        <Typography.Text style={{ color: 'red' }}>({coupon.coupon.product.name})</Typography.Text>
                    }
                </Col>
                <Col xs={14} style={{ textAlign: "right" }}>
                    <Typography.Title style={{ color: 'red' }} level={3}>
                        <NumberFormat displayType="text"
                            decimalScale={2}
                            decimalSeparator=","
                            prefix="R$ "
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            value={getDiscount()}
                        />
                    </Typography.Title>
                </Col>
            </Row>
            <Row style={{ paddingLeft: 30, paddingRight: 30 }}>
                <Col xs={10} style={{ textAlign: "left" }}>
                    <Typography.Title level={3}>Total:</Typography.Title>
                </Col>
                <Col xs={14} style={{ textAlign: "right" }}>
                    <Typography.Title level={3}>
                        <NumberFormat displayType="text"
                            decimalScale={2}
                            decimalSeparator=","
                            prefix="R$ "
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            value={getSummary()}
                        />
                    </Typography.Title>
                </Col>
            </Row>
            {!payment.isPayment &&
                <Row style={{ marginTop: 50, paddingLeft: 30, paddingRight: 30 }}>
                    <Col xs={24} style={{ textAlign: "right" }}>
                        <Button type="primary" icon={<CreditCardOutlined />} onClick={onPayment}>Ir para Pagamento</Button>
                    </Col>
                    <Col xs={24} style={{ marginTop: 20 }}>
                        {payment.message &&
                            <Alert message={payment.message} type="error" showIcon closable={true} />
                        }
                    </Col>
                </Row>
            }
        </>
    )
}

const mapStateToProps = state => ({
    cart: state.cart,
    coupon: state.coupon,
    payment: state.payment
})
export default connect(mapStateToProps, {
    plusCartAction,
    minusCartAction,
    searchByCodeAction,
    setPaymentAction,
    removeCartAction,
    setPaymentMessageAction,
    setTotalCartAction
})(CheckoutCart);