import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { authenticateAction } from 'actions/auth_action';
import Checkout from './views/Checkout';

const App = (props) => {
  const { authenticateAction, auth } = props;
  React.useEffect(() => {
    authenticateAction();
  }, [authenticateAction]);

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Header></Layout.Header>
      <Layout.Content>
        {auth.isAuth &&
          <Checkout />
        }
      </Layout.Content>
    </Layout>
  );
}

const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(mapStateToProps, { authenticateAction })(App);;
