import { createStore, applyMiddleware, compose } from 'redux';
import axios from 'axios';
import rootReducer from './reducers'
import thunk from 'redux-thunk';
import { serverURL } from './env'
import { getAuthorization } from 'util/localstorage'


axios.defaults.baseURL = serverURL;
axios.defaults.responseType = 'json';
axios.defaults.contentType = 'json';

axios.interceptors.request.use(
    async (config) => {
        const authorization = getAuthorization();
        if (authorization) {
            config.headers.Authorization = `${authorization}`;
        }
        return config;
    },
    error => Promise.reject(error)
)

export default function configureStore() {
    const store = createStore(
        rootReducer,
        compose(
            applyMiddleware(thunk),
        ),
    )
    return store
}