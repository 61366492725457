const INITIAL_STATE = {
    products: [],
    total: { amount: 0, discount: 0 }
}

const CartReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'ADD_CART': {
            const index = state.products.findIndex(x => x.id === payload.id)
            if (index > -1) {
                //let newProducts = [...state.products];
                //newProducts[index] = { ...newProducts[index], quantity: newProducts[index].quantity + 1 }
                //return Object.assign({}, state, {
                //    products: newProducts
                //});
                return Object.assign({}, state, {
                    products: state.products
                });
            } else {
                return Object.assign({}, state, {
                    products: [...state.products, payload]
                });
            }
        }
        case 'REMOVE_CART': {
            return Object.assign({}, state, {
                products: state.products.filter(x => x.id !== payload)
            });
        }
        case 'PLUS_CART': {
            const index = state.products.findIndex(x => x.id === payload)
            if (index > -1) {
                let newProducts = [...state.products];
                newProducts[index] = { ...newProducts[index], quantity: newProducts[index].quantity + 1 }
                return Object.assign({}, state, {
                    products: newProducts
                });
            }
            return Object.assign({}, state, {
                products: state.products
            });
        }
        case 'MINUS_CART': {
            const index = state.products.findIndex(x => x.id === payload)
            if (index > -1 && state.products[index].quantity > 1) {
                let newProducts = [...state.products];
                newProducts[index] = { ...newProducts[index], quantity: newProducts[index].quantity - 1 }
                return Object.assign({}, state, {
                    products: newProducts
                });
            }
            return Object.assign({}, state, {
                products: state.products
            });
        }
        case 'TOTAL_CART': {
            return Object.assign({}, state, {
                total: payload
            });
        }
        default:
            return state;
    }
}

export default CartReducer;
