export const addCartAction = (item) => async (dispatch) => {
    dispatch({
        type: 'ADD_CART',
        payload: {
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: 1
        }
    });
    return item;
}

export const removeCartAction = (id) => async (dispatch) => {
    dispatch({
        type: 'REMOVE_CART',
        payload: id
    });

    return id;
}

export const plusCartAction = (id) => async (dispatch) => {
    dispatch({
        type: 'PLUS_CART',
        payload: id
    });

    return id;
}

export const minusCartAction = (id) => async (dispatch) => {
    dispatch({
        type: 'MINUS_CART',
        payload: id
    });

    return id;
}

export const setTotalCartAction = (amount, discount) => async (dispatch) => {
    let total = { amount: amount, discount: discount };
    dispatch({
        type: 'TOTAL_CART',
        payload: total
    });

    return total;
}