import { combineReducers } from 'redux';
import AuthReducer from './auth_reducer';
import ProductReducer from './product_reducer';
import CartReducer from './cart_reducer';
import CouponReducer from './coupon_reducer';
import PaymentReducer from './payment_reducer';
import CheckoutReducer from './checkout_reducer';

const appReducer = combineReducers({
    auth: AuthReducer,
    product: ProductReducer,
    cart: CartReducer,
    coupon: CouponReducer,
    payment: PaymentReducer,
    checkout: CheckoutReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
}

export default rootReducer;